import styled from 'styled-components'

export const StyledSummaryCardContent = styled.div`
  text-align: center;
  width: 100%;
  margin: 10px 0;
`
export const StyledText = styled.p`
  margin: 0 5px 0 0;
  color: #828282;
`
export const StyledCardHeader = styled.div`
  margin-bottom: 10px;
`
export const StyledTextStrong = styled.strong``
export const StyledCardContent = styled.div`
  display: flex;
  justify-content: center;
`
export const StyledFooterContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`
