import React from 'react'
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import ReportOperationsTypeContainer from '../../../../containers/ReportOperationsTypeContainer'
import LayoutMain from '../../../../layout'

const OperationsType: React.FC = () => {
  return (
    <LayoutMain>
      <WithPrivateRoute component={ReportOperationsTypeContainer} />
    </LayoutMain>
  )
}

export default OperationsType
