import React from 'react'
import { navigate } from 'gatsby'
import { List, Empty } from 'antd'
import moment from 'moment'
import { ReportContainer, StyledTitleReport } from './styledComponents'
import Amount from '../Amount/index'
import 'moment/locale/es'

moment.locale('es')

type typeTableReportContents = {
  DataReports: any
  titleTable?: string
}

const TableReportContent: React.FC<typeTableReportContents> = ({
  DataReports,
  titleTable
}) => {
  return (
    <ReportContainer>
      <StyledTitleReport>{titleTable}</StyledTitleReport>
      <List
        itemLayout="horizontal"
        dataSource={DataReports}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Sin operaciones"
            />
          )
        }}
        renderItem={(item: any) => {
          return (
            <List.Item
              data-testid="urlNavigate"
              onClick={() => {
                item.urlNavigate && navigate(`${item.urlNavigate}`)
              }}
            >
              {item.iconLeft}
              <List.Item.Meta
                title={`${item.title}`}
                description={item.subTitle}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Amount
                  amount={item.amount}
                  amountSize={'18'}
                  currecySize={'10'}
                  centsSize={'10'}
                />
                {item.iconRigth}
              </div>
            </List.Item>
          )
        }}
      />
    </ReportContainer>
  )
}

export default TableReportContent
