import { Col, Row } from 'antd'
import React from 'react'
import Amount from '../Amount'
import {
  StyledCardHeader,
  StyledSummaryCardContent,
  StyledText,
  StyledTextStrong,
  StyledCardContent
} from './styledComponents'

type props = {
  titleHeader?: string
  textHeader?: string
  titleContent: string
  textContent: string
  startDate?: string
  endDate?: string
  textLinkFooter?: string
  linkFooter?: string
}
const SummaryCardComponent: React.FC<props> = ({
  titleHeader,
  textHeader,
  titleContent,
  startDate,
  endDate,
  textContent
}) => {
  return (
    <StyledSummaryCardContent>
      <Row gutter={16} align="middle">
        <Col xs={24} sm={8} md={8} lg={8}>
          <StyledCardHeader>
            <StyledText>{titleHeader}</StyledText>
            <StyledText>{textHeader}</StyledText>
          </StyledCardHeader>
        </Col>
        <Col xs={24} sm={8} md={8} lg={8}>
          <StyledTextStrong>{titleContent}</StyledTextStrong>
          <StyledCardContent>
            <Amount
              amount={textContent}
              amountSize={'30'}
              currecySize={'15'}
              centsSize={'15'}
            />
          </StyledCardContent>
          {startDate && endDate && (
            <StyledText>{`del ${startDate} al ${endDate}`}</StyledText>
          )}
        </Col>
      </Row>
    </StyledSummaryCardContent>
  )
}

export default SummaryCardComponent
