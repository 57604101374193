import styled from 'styled-components'
export const ReportContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  .ant-list-items {
    cursor: pointer;
  }
`
export const StyledTitleReport = styled.p`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1a1a1a;
  margin-top: 10px;
`
