//Packages
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import { Row } from 'antd'
import moment from 'moment'

//Components
import SummaryCardComponent from '../../components/SummaryCard'
import TableReportContent from '../../components/TableReportContent'
import TitleReport from '../../components/TitleReport'
import IconComponent from '../../components/Icon'
import Loading from '../../components/Shared/Loading'

//Assets
import card from '../../data/assets/images/card.png'
import money from '../../data/assets/images/money.png'
import point from '../../data/assets/images/point.png'
import ticket from '../../data/assets/images/ticket.png'

//GraphQL
import { GET_CURRENT_MONTH_SALES } from '../../graphql/queries/Reports'
import { getParameter } from '../../utils/queryString'
import { StyledOperationsTypeContent } from './styledComponents'

const ReportOperationsTypeContainer: React.FC = () => {
  const [paymentTypes, setPaymentTypes]: any = useState([])
  const {
    data: getCurrentMonthSalesData,
    loading: getCurrentMonthSalesLoading
  } = useQuery(GET_CURRENT_MONTH_SALES, {
    variables: { sellerId: getParameter('sellerId') },
    context: { clientName: 'api-instore' }
  })

  useEffect(() => {
    if (
      getCurrentMonthSalesData &&
      getCurrentMonthSalesData.currentMonthSales &&
      getCurrentMonthSalesData.currentMonthSales.monthlySummary &&
      !getCurrentMonthSalesLoading
    ) {
      const dataReportItems: Array<any> = []
      getCurrentMonthSalesData.currentMonthSales.paymentTypeSummary.forEach(
        (element: any) => {
          dataReportItems.push({
            title: getTitleTypePayment(element.paymentType),
            subTitle:
              element.counter > 1 || element.counter === 0
                ? `${element.counter} operaciones`
                : `${element.counter} operación`,
            amount: element.amount.toFixed(2).toString(),
            urlNavigate: null,
            iconRigth: null,
            iconLeft: (
              <IconComponent
                icon={getIconTypePayment(element.paymentType)}
                height={20}
                width={20}
              />
            )
          })
        }
      )
      setPaymentTypes(dataReportItems)
    }
  }, [getCurrentMonthSalesData, getCurrentMonthSalesLoading])

  const getTitleTypePayment = (paymentType: string) => {
    let title = ''
    switch (paymentType) {
      case 'CARD':
        title = 'Pago con tarjeta'
        break
      case 'CASH':
        title = 'Pago con efectivo'
        break
      case 'LOYALTY':
        title = 'Pago con loyalty'
        break
      case 'COMBINADO':
        title = 'Pago combinado'
        break
      default:
        break
    }
    return title
  }

  const getIconTypePayment = (paymentType: string) => {
    let icon = money
    switch (paymentType) {
      case 'CARD':
        icon = card
        break
      case 'CASH':
        icon = money
        break
      case 'LOYALTY':
        icon = point
        break
      case 'COMBINADO':
        icon = ticket
        break
      default:
        break
    }
    return icon
  }

  return (
    <StyledOperationsTypeContent>
      <Row gutter={16}>
        <TitleReport title={'TIPO DE OPERACIÓN'} onBack={() => navigate(-1)} />
      </Row>
      {!getCurrentMonthSalesLoading && (
        <>
          <Row gutter={16}>
            <SummaryCardComponent
              titleHeader={'MODELORAMA'}
              textHeader={getParameter('name')}
              titleContent={'Total de ventas'}
              textContent={
                getCurrentMonthSalesData &&
                getCurrentMonthSalesData.currentMonthSales &&
                getCurrentMonthSalesData.currentMonthSales.monthlySummary
                  ? getCurrentMonthSalesData.currentMonthSales.monthlySummary.amount
                      .toFixed(2)
                      .toString()
                  : '00'
              }
              startDate={'1'}
              endDate={`${moment().subtract(1, 'days').date()} de  ${moment()
                .startOf('month')
                .format('MMMM')}`}
              textLinkFooter={
                getCurrentMonthSalesData &&
                getCurrentMonthSalesData.currentMonthSales &&
                getCurrentMonthSalesData.currentMonthSales.monthlySummary
                  ? getCurrentMonthSalesData.currentMonthSales.monthlySummary
                      .counter > 1
                    ? `${getCurrentMonthSalesData.currentMonthSales.monthlySummary.counter} operaciones`
                    : `${getCurrentMonthSalesData.currentMonthSales.monthlySummary.counter} operación`
                  : 'Ninguna operación'
              }
            />
          </Row>
          <hr />
          <Row gutter={16}>
            <TableReportContent
              DataReports={paymentTypes}
              titleTable={'Resumen de operaciones'}
            />
          </Row>
        </>
      )}
      {getCurrentMonthSalesLoading && <Loading />}
    </StyledOperationsTypeContent>
  )
}

export default ReportOperationsTypeContainer
