import styled from 'styled-components'
import { Row } from 'antd'

interface TextStyle {
  textCenter?: boolean
}

export const StyledContainerTitlesection = styled(Row)<TextStyle>`
  display: flex;
  justify-content: ${(props) => (props.textCenter ? 'center' : 'flex-start')};
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  p {
    margin: 0;
  }
  h2.custom-large-text {
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: 30vh;
  }
  p.custom-subtitle {
    font-weight: normal;
    color: black;
    text-align: center;
    margin: 0px;
  }
`
