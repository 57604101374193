import React from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { StyledContainerTitlesection } from './styledComponents'

type typeTitleReport = {
  title: any
  onBack?: any
  textLarge?: boolean
  textCenter?: boolean
  subTitle?: string | boolean
}

const TitleReport: React.FC<typeTitleReport> = ({
  title,
  onBack,
  textLarge,
  textCenter,
  subTitle
}) => {
  return (
    <StyledContainerTitlesection textCenter={textCenter}>
      <LeftOutlined onClick={onBack} hidden={onBack ? false : true} />
      {textLarge ? (
        <h2 className="custom-large-text">{title}</h2>
      ) : (
        <p>{title}</p>
      )}
      {subTitle ? <p className="custom-subtitle">{subTitle}</p> : ''}
    </StyledContainerTitlesection>
  )
}

export default TitleReport
